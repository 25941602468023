enum DreamsMockEnumId {
    personalPurchase = '61fda5cb3584d71c40256436',
    partiesGifts = '61fda5ca3584d71c4025642a',
    houseProperties = '61fda5c13584d71c402563fd',
    vehicle = '61fda5c93584d71c40256418',
    travelLeisure = '61fda5c93584d71c40256420',
    education = '61fda5cd3584d71c40256448',
}

enum ObjectivePeriodicityEnum {
    unico = 'Único',
    recorrente = 'Recorrente',
}

export const CalculateObjectives = (objectives: any, startProjectDate: Date, ageIF: number, age: number) => {
    const yearsUntilIndependence = ageIF - age;

    const date = new Date(startProjectDate);

    const month = date.getMonth() + 1;

    const monthsInFirstYear = (12 - month)

    let item = {
        category: '',
        name: '',
        periodicity: ObjectivePeriodicityEnum.recorrente,
        value: 0,
        changeVehicleTime: 0,
        paymentMethodEducation: '',
        paymentDurationEducation: 0,
        habitationPayment: '',
        habitationFinanceValue: 0,
        year: 0,
        untilIndependence: 0
    }

    let arrHouseProperties = []
    let arrVehicle = []
    let arrTravelLeisure = []
    let arrEducation = []
    let arrPersonalPurchases = []
    let arrPartiesGifts = []
    let arrAll = []

    let totalHouseProperties = 0
    let totalVehicle = 0
    let totalTravelLeisure = 0
    let totalEducation = 0
    let totalPersonalPurchases = 0
    let totalPartiesGifts = 0
    let total = 0

    let untilIndependenceTravelLeisure = 0
    let untilIndependencePersonalPurchases = 0
    let untilIndependenceParties = 0
    let untilIndependenceGifts = 0

    objectives.forEach(el => {
        switch (el.category._id) {
            case DreamsMockEnumId.houseProperties:
                item = {
                    category: DreamsMockEnumId.houseProperties,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.category._id.toString() === '61fda5c13584d71c402563fd' &&
                    el.habitationPayment === 'Financiado'
                ) {

                    totalHouseProperties = totalHouseProperties + el.value + (el.habitationFinanceValue || 0);
                } else {
                    totalHouseProperties = totalHouseProperties + el.value;
                }
                arrHouseProperties.push(item)
                break;
            case DreamsMockEnumId.vehicle:
                item = {
                    category: DreamsMockEnumId.vehicle,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.changeVehicleTime && el.changeVehicleTime != 0) {
                    const now = new Date()
                    const dateOfFirstAchievement = el.year;

                    const yearOfFinancialIndependence =
                        now.getFullYear() + yearsUntilIndependence;
                    const every = el.changeVehicleTime || 0;

                    for (
                        let h = dateOfFirstAchievement;
                        h <= yearOfFinancialIndependence;
                        h = h + every
                    ) {
                        if (h <= yearOfFinancialIndependence) {
                            totalVehicle = totalVehicle + el.value;
                        }
                    }
                } else {
                    totalVehicle = totalVehicle + el.value
                }
                arrVehicle.push(item)
                break;
            case DreamsMockEnumId.travelLeisure:
                item = {
                    category: DreamsMockEnumId.travelLeisure,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
                    const now = new Date()
                    const dateOfFirstAchievement = el.year;

                    const yearOfFinancialIndependence =
                        now.getFullYear() + yearsUntilIndependence;
                    const every = 1;
                    for (
                        let h = dateOfFirstAchievement;
                        h <= yearOfFinancialIndependence;
                        h = h + every
                    ) {
                        if (h <= yearOfFinancialIndependence) {
                            totalTravelLeisure = totalTravelLeisure + el.value;
                        }
                    }
                } else {
                    totalTravelLeisure = totalTravelLeisure + el.value
                }
                arrTravelLeisure.push(item)
                break;
            case DreamsMockEnumId.education:
                item = {
                    category: DreamsMockEnumId.education,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.EducationPayment === 'Mensalidade') {
                    totalEducation = totalEducation + (el.value * (el.EducationPaymentDuration || 1))
                } else {
                    totalEducation = totalEducation + el.value
                }
                arrEducation.push(item)
                break;
            case DreamsMockEnumId.personalPurchase:
                item = {
                    category: DreamsMockEnumId.personalPurchase,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
                    const now = new Date()
                    const dateOfFirstAchievement = el.year;

                    const yearOfFinancialIndependence =
                        now.getFullYear() + yearsUntilIndependence;
                    const every = 1;
                    for (
                        let h = dateOfFirstAchievement;
                        h <= yearOfFinancialIndependence;
                        h = h + every
                    ) {
                        if (h <= yearOfFinancialIndependence) {
                            totalPersonalPurchases = totalPersonalPurchases + el.value;
                        }
                    }
                } else {
                    totalPersonalPurchases = totalPersonalPurchases + el.value
                }
                arrPersonalPurchases.push(item)
                break;
            case DreamsMockEnumId.partiesGifts:
                item = {
                    category: DreamsMockEnumId.partiesGifts,
                    name: String(el.name),
                    periodicity: el.periodicity || ObjectivePeriodicityEnum.unico,
                    value: el.value,
                    changeVehicleTime: el.changeVehicleTime || 0,
                    paymentMethodEducation: el.EducationPayment || '',
                    paymentDurationEducation: el.EducationPaymentDuration || 0,
                    habitationPayment: el.habitationPayment || '',
                    habitationFinanceValue: el.habitationFinanceValue || 0,
                    year: el.year,
                    untilIndependence: 0
                }
                if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
                    const now = new Date()
                    const dateOfFirstAchievement = el.year;

                    const yearOfFinancialIndependence =
                        now.getFullYear() + yearsUntilIndependence;
                    const every = 1;
                    for (
                        let h = dateOfFirstAchievement;
                        h <= yearOfFinancialIndependence;
                        h = h + every
                    ) {
                        if (h <= yearOfFinancialIndependence) {
                            totalPartiesGifts = totalPartiesGifts + el.value;
                        }
                    }
                } else {
                    totalPartiesGifts = totalPartiesGifts + el.value
                }
                arrPartiesGifts.push(item)
                break;
        }
    })

    arrPersonalPurchases.forEach(el => {
        if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
            const now = new Date()
            const dateOfFirstAchievement = el.year;

            const yearOfFinancialIndependence =
                now.getFullYear() + yearsUntilIndependence;
            const every = 1;
            for (
                let h = dateOfFirstAchievement;
                h <= yearOfFinancialIndependence;
                h = h + every
            ) {
                if (h <= yearOfFinancialIndependence) {
                    untilIndependencePersonalPurchases = untilIndependencePersonalPurchases + el.value;
                }
            }
        }
        if (el.name == 'Reserva para compras') {
            el.untilIndependence = untilIndependencePersonalPurchases
        } else {
            el.untilIndependence = el.value
        }
        arrAll.push(el)
    })
    arrPartiesGifts.forEach(el => {
        if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
            if (el.name == 'Reserva para festas') {
                const now = new Date()
                const dateOfFirstAchievement = el.year;

                const yearOfFinancialIndependence =
                    now.getFullYear() + yearsUntilIndependence;
                const every = 1;
                for (
                    let h = dateOfFirstAchievement;
                    h <= yearOfFinancialIndependence;
                    h = h + every
                ) {
                    if (h <= yearOfFinancialIndependence) {
                        untilIndependenceParties = untilIndependenceParties + el.value;
                    }
                }
            } else {
                const now = new Date()
                const dateOfFirstAchievement = el.year;

                const yearOfFinancialIndependence =
                    now.getFullYear() + yearsUntilIndependence;
                const every = 1;
                for (
                    let h = dateOfFirstAchievement;
                    h <= yearOfFinancialIndependence;
                    h = h + every
                ) {
                    if (h <= yearOfFinancialIndependence) {
                        untilIndependenceGifts = untilIndependenceGifts + el.value;
                    }
                }
            }
        }
        if (el.name != 'Reserva para festas' && el.name != 'Reserva para presentes') {
            el.untilIndependence = el.value
        } else {
            el.name === 'Reserva para festas' ? el.untilIndependence = untilIndependenceGifts : null
            el.name === 'Reserva para presentes' ? el.untilIndependence = untilIndependenceGifts : null
        }
        arrAll.push(el)
    })
    arrHouseProperties.forEach(el => {
        if (el.habitationPayment === 'Financiado') {
            el.untilIndependence = el.value + (el.habitationFinanceValue || 0)
        } else {
            el.untilIndependence = el.value
        }
        arrAll.push(el)
    })
    arrVehicle.forEach(el => {
        if (el.changeVehicleTime != 0) {
            let multiplier = yearsUntilIndependence / (el.changeVehicleTime || 0)
            el.untilIndependence = (el.value * multiplier)
        } else {
            el.untilIndependence = el.value
        }
        arrAll.push(el)
    })
    arrTravelLeisure.forEach(el => {
        if (el.periodicity == ObjectivePeriodicityEnum.recorrente) {
            const now = new Date()
            const dateOfFirstAchievement = el.year;

            const yearOfFinancialIndependence =
                now.getFullYear() + yearsUntilIndependence;
            const every = 1;
            for (
                let h = dateOfFirstAchievement;
                h <= yearOfFinancialIndependence;
                h = h + every
            ) {
                if (h <= yearOfFinancialIndependence) {
                    untilIndependenceTravelLeisure = untilIndependenceTravelLeisure + el.value;
                }
            }
        }
        if (el.name != 'Reserva para viagens') {
            el.untilIndependence = el.value
        } else {
            el.untilIndependence = untilIndependenceTravelLeisure
        }
        arrAll.push(el)
    })
    arrEducation.forEach(el => {
        if (el.EducationPayment === 'Mensalidade') {
            el.untilIndependence = el.value * el.EducationPayment
        } else {
            el.untilIndependence = el.value
        }
        arrAll.push(el)
    })

    arrAll.sort(compareObjects);

    function compareObjects(a, b) {
        if (a.untilIndependence < b.untilIndependence) {
            return 1;
        } else if (a.untilIndependence > b.untilIndependence) {
            return -1;
        } else {
            return 0;
        }
    }

    total = totalHouseProperties + totalVehicle + totalTravelLeisure + totalEducation + totalPersonalPurchases + totalPartiesGifts

    const response = {
        arrHouseProperties,
        arrVehicle,
        arrTravelLeisure,
        arrEducation,
        arrPersonalPurchases,
        arrPartiesGifts,
        arrAll,
        totalHouseProperties,
        totalVehicle,
        totalTravelLeisure,
        totalEducation,
        totalPersonalPurchases,
        totalPartiesGifts,
        total,
        untilIndependenceTravelLeisure,
        untilIndependencePersonalPurchases,
        untilIndependenceParties,
        untilIndependenceGifts,
        yearsUntilIndependence,
        monthsInFirstYear
    }

    return response
}