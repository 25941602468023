import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CombinedDataPurchasesInterface } from 'src/app/dtos/combined-data-purchases.dto';
import { AdvisorService } from 'src/app/services/advisor.service';
import { convertToMoney } from 'src/app/utils/convertToMoney';
import { FormatDate } from 'src/app/utils/formatDate.util';

@Component({
  selector: 'buys-gold-resume',
  templateUrl: './buys-gold-resume.component.html',
  styleUrls: ['./buys-gold-resume.component.scss']
})
export class BuysGoldResumeComponent implements OnInit {

  users: CombinedDataPurchasesInterface[] = [];
  specialistEmail: string = '';
  showPaymentColumn = false;

  formatDate = FormatDate;
  convertToMoney = convertToMoney;

  constructor(
    private readonly ngxSpinnerService: NgxSpinnerService,
    private readonly advisorService: AdvisorService,
    private route: ActivatedRoute
  ) { }

  async ngOnInit() {
    this.ngxSpinnerService.show();
    this.route.queryParams.subscribe(params => {
      this.specialistEmail = params['specialistEmail'];
    });
    await this.getData();
  }

  async getData() {
    try {
      if (this.specialistEmail === 'leonardo.borges@gwx.com.br') {
        const resultBuysGold = await this.advisorService.listBuysGoldBySpecialist(this.specialistEmail).toPromise();
        const resultRevenueCat = await this.advisorService.listRevenueCatPurchasesBySpecialistForConcat(this.specialistEmail).toPromise();
        const combinedData = resultBuysGold.data.concat(resultRevenueCat.data);
        console.log(combinedData)
        this.users = combinedData;
        this.updateShowPaymentColumn();
        this.ngxSpinnerService.hide();
      } else {
        const result = await this.advisorService.listRevenueCatPurchasesBySpecialistForConcat(this.specialistEmail).toPromise();
        this.users = result.data;
        this.updateShowPaymentColumn();
        this.ngxSpinnerService.hide();
      }
    } catch (error) {
      console.error("Erro ao obter os dados da API:", error);
    }
  }

  async changeCheckCommissionPayment(event, id: string) {
    this.ngxSpinnerService.show();
    await this.advisorService.updateCheckCommissionPayment({ id, checkCommissionPayment: event.target.checked }).toPromise();
    await this.getData();
    this.ngxSpinnerService.hide();
  }

  updateShowPaymentColumn() {
    this.showPaymentColumn = this.users.some(user => user.approvedDate !== undefined && user.approvedDate !== '');
  }

}
