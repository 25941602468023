<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item" aria-current="page">
      <a [routerLink]="['/pages/dashboard']">Dashboard</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">Ciclo de vida</li>
  </ol>
</nav>
<div class="card card-radius mb-3">
  <div class="card-body">
    <div class="form-group">
      <label for="term">Buscar</label>
      <div class="d-flex" style="align-items: end">
        <div class="input-group">
          <input (input)="filter.text = $event.target.value" type="text" class="form-control mr-4"
            placeholder="Digite o texto para buscar" [value]="filter.text" />
        </div>
        <mat-form-field class="example-form-field" appearance="fill">
          <mat-date-range-input [formGroup]="dateRangeTest" [rangePicker]="dateRangeTestPicker">
            <input matStartDate placeholder="Data inicial" formControlName="initialDate" />
            <input matEndDate placeholder="Data final" formControlName="finalDate" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="dateRangeTestPicker" style="color: aqua"></mat-datepicker-toggle>
          <mat-date-range-picker class="bg-white" #dateRangeTestPicker></mat-date-range-picker>
        </mat-form-field>
        <button (click)="list()" class="btn btn-primary">Buscar</button>
        <button (click)="clear()" class="btn btn-secondary ml-2">Limpar</button>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body p-3">
      <div class="buttons-export d-flex">
        <div class="w-50 d-flex justify-content-start">
          <a (click)="exportNamePhone()" class="btn btn-outline-secondary">Exportar dados</a>
          <a (click)="exportAll()" class="btn btn-outline-primary ml-2">Exportar todos</a>
        </div>
        <div class="w-50 d-flex justify-content-end">
          <!-- <a (click)="selectAll()" class="btn btn-outline-secondary">Selecionar todos</a> -->
          <a (click)="routeViewClients()" class="btn btn-outline-secondary">Visualizar selecionados</a>
          <a (click)="showGraphic()" class="btn btn-outline-secondary ml-2">{{ displayNone ? "Visualizar tabela" :
            "Visualizar gráfico" }}
          </a>
        </div>
      </div>
      <div [ngClass]="displayNone ? 'd-none' : 'table-responsive'">
        <table class="table table-sm table-hover" id="table-data">
          <thead>
            <tr>
              <th scope="col">
                <input (change)="selectCurrentPage()" [checked]="currentPageIsChecked" type="checkbox" />
              </th>
              <th class="border-left" scope="col">Nome</th>
              <th class="border-left" scope="col">Data de Nascimento</th>
              <th class="border-left" scope="col">Profissão</th>
              <th class="border-left" scope="col">E-mail</th>
              <th class="border-left" scope="col">Telefone</th>
              <th class="border-left" scope="col">SO</th>
              <th class="border-left" scope="col">ID</th>
              <th class="border-left" scope="col">Renda Atual</th>
              <th class="border-left" scope="col">Invest.</th>
              <th class="border-left" scope="col">Descobriu Número?</th>
              <th class="border-left" scope="col">Open Finance</th>
              <th class="border-left" scope="col">Premium</th>
              <th class="border-left" scope="col">Data</th>
              <th class="border-left" scope="col">Exportar PDF</th>
            </tr>
          </thead>
          <br />
          <tbody>
            <tr *ngFor="
              let item of resultList
                | paginate
                  : { itemsPerPage: itemsPerPage, currentPage: currentPage };
              let i = index
            ">
              <td>
                <input [id]="'Checkbox' + i" (change)="
                  selectedClient(
                    $event,
                    item.name,
                    item.birthDate ? FormatDate(item.birthDate) : '',
                    item.occupation,
                    item.phone,
                    item.email,
                    item.advisorID,
                    item.currentIncome,
                    item.investments?.appliedValue,
                    item.discoverNumber,
                    item.createdAt ? FormatDate(item.createdAt) : '',
                    item.premium
                  )
                " [checked]="isChecked" type="checkbox" />
              </td>
              <td [ngClass]="item.name ? '' : 'empty-field'">
                {{ item.name ? item.name : "*" }}
              </td>
              <td [ngClass]="item.birthDate ? '' : 'empty-field'">
                {{ item.birthDate ? FormatDate(item.birthDate) : "*" }}
              </td>
              <td [ngClass]="item.occupation ? '' : 'empty-field'">
                {{ item.occupation ? item.occupation : "*" }}
              </td>
              <td [ngClass]="item.email ? '' : 'empty-field'">
                {{ item.email ? item.email : "*" }}
              </td>
              <td [ngClass]="item.phone ? '' : 'empty-field'">
                {{ item.phone ? item.phone : "*" }}
              </td>

              <td [ngClass]="item.deviceOperatingSystem ? '' : 'empty-field'">
                {{
                item.deviceOperatingSystem
                ? item.deviceOperatingSystem.toUpperCase()
                : "n/a"
                }}
              </td>
              <td [ngClass]="item.advisorID ? '' : 'empty-field'">
                {{ item.advisorID ? item.advisorID : "*" }}
              </td>

              <td>
                <ng-container *ngIf="!item.currentIncome">
                  <i class="fas fa-times text-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.currentIncome">{{
                  item.currentIncome % 1 !== 0
                  ? item.currentIncome.toFixed(2)
                  : item.currentIncome
                  }}</ng-container>
              </td>
              <td>
                <ng-container *ngIf="!item.investments?.appliedValue">
                  <i class="fas fa-times text-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.investments?.appliedValue">{{
                  item.investments?.appliedValue
                  }}</ng-container>
              </td>
              <td>
                <ng-container *ngIf="!item.discoverNumber">
                  <i class="fas fa-times text-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.discoverNumber"><i class="fas fa-check text-success"></i>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!item.klaviConnected">
                  <i class="fas fa-times text-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.klaviConnected"><i class="fas fa-check text-success"></i>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="!item.premium">
                  <i class="fas fa-times text-danger"></i>
                </ng-container>
                <ng-container *ngIf="item.premium"><i class="fas fa-check text-success"></i>
                </ng-container>
              </td>
              <td [ngClass]="item.createdAt ? '' : 'empty-field'">
                {{ item.createdAt ? FormatDate(item.createdAt) : "*" }}
              </td>
              <td (click)="exportUserPDF(item.email)" [ngClass]="userHavePdf(item.email) ? '' : 'empty-field'"
                *ngIf="userHavePdf(item.email)">
                <em class="fas fa-file-pdf"></em>
              </td>
              <td *ngIf="!userHavePdf(item.email)">*</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Botões de paginação -->
      <div [ngClass]="displayNone ? 'd-none' : 'd-flex justify-content-end'">
        <div class="d-flex">
          <app-table-size-selector (tableSizeChange)="onTableSizeChange($event)"></app-table-size-selector>

          <pagination-controls (pageChange)="onPageChange($event)" previousLabel="Anterior" nextLabel="Próximo">
          </pagination-controls>
        </div>
      </div>

      <!-- Gráfico pizza  -->
      <div [ngClass]="!displayNone ? 'd-none' : 'container mt-5 mb-5'">
        <h1 class="fw-bold display-5 text-center text-blue">
          Quantidade de usuários: {{ resultList?.length }}
        </h1>
        <div class="row d-flex justify-content-center align-items-center mt-5 text-center">
          <div class="col-md-6 mt-5 mb-5">
            <h3>Seguro</h3>
            <apx-chart [series]="chartInsuranceOptions?.series" [chart]="chartInsuranceOptions?.chart"
              [labels]="chartInsuranceOptions?.labels" [responsive]="chartInsuranceOptions?.responsive"
              [legend]="chartInsuranceOptions?.legend">
            </apx-chart>
          </div>
          <div class="col-md-6 mt-5 mb-5">
            <h3>Renda</h3>
            <apx-chart [series]="chartIncomeDataOptions?.series" [chart]="chartIncomeDataOptions?.chart"
              [labels]="chartIncomeDataOptions?.labels" [responsive]="chartIncomeDataOptions?.responsive"
              [legend]="chartIncomeDataOptions?.legend">
            </apx-chart>
          </div>
          <div class="col-md-6 mt-5 mb-5">
            <h3>Independência Financeira</h3>
            <apx-chart [series]="chartIfOptions?.series" [chart]="chartIfOptions?.chart"
              [labels]="chartIfOptions?.labels" [responsive]="chartIfOptions?.responsive"
              [legend]="chartIfOptions?.legend">
            </apx-chart>
          </div>
          <div class="col-md-6 mt-5 mb-5">
            <h3>Objetivos</h3>
            <apx-chart [series]="chartObjectivesOptions?.series" [chart]="chartObjectivesOptions?.chart"
              [labels]="chartObjectivesOptions?.labels" [responsive]="chartObjectivesOptions?.responsive"
              [legend]="chartObjectivesOptions?.legend">
            </apx-chart>
          </div>
          <div class="col-md-6 mt-5 mb-5">
            <h3>Patrimônio</h3>
            <apx-chart [series]="chartPatrimonyOptions?.series" [chart]="chartPatrimonyOptions?.chart"
              [labels]="chartPatrimonyOptions?.labels" [responsive]="chartPatrimonyOptions?.responsive"
              [legend]="chartPatrimonyOptions?.legend">
            </apx-chart>
          </div>
          <div class="col-md-6 mt-5 mb-5">
            <h3>Custo Fixo</h3>
            <apx-chart [series]="chartFixedCostOptions?.series" [chart]="chartFixedCostOptions?.chart"
              [labels]="chartFixedCostOptions?.labels" [responsive]="chartFixedCostOptions?.responsive"
              [legend]="chartFixedCostOptions?.legend">
            </apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>