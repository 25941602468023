<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
            <a [routerLink]="['/pages/dashboard']">Dashboard</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Remover clientes</li>
    </ol>
</nav>
<div class="card card-radius mb-3">
    <div class="card-body">

        <div class="form-group">
            <label for="term">Buscar</label>
            <div class="input-group">
                <input type="text" class="form-control" placeholder="Digite o texto para buscar" [(ngModel)]="term">
            </div>
        </div>
    </div>
</div>
<div class="card card-radius mt-3">
    <div class="card-body">
        <div class="table-responsive card-radius">
            <table class="table table-sm table-hover table-striped">
                <thead class="button-color">
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Data de Nascimento</th>
                        <th scope="col">Profissão</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Telefone</th>
                        <th scope="col">ID</th>
                        <th scope="col">Data Projeto</th>
                        <th scope="col">Excluir</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of resultList | filter:term | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }; let i= index">
                        <td [ngClass]="item.name ? '' : 'empty-field'">{{item.name ? item.name : '*'}}</td>
                        <td [ngClass]="item.birthDate ? '' : 'empty-field'">{{item.birthDate ? formatDate(item.birthDate) : '*'}}</td>
                        <td [ngClass]="item.occupation ? '' : 'empty-field'">{{item.occupation ? item.occupation : '*'}}</td>
                        <td [ngClass]="item.email ? '' : 'empty-field'">{{item.email ? item.email : '*'}}</td>
                        <td [ngClass]="item.phone ? '' : 'empty-field'">{{item.phone ? item.phone : '*'}}</td>
                        <td [ngClass]="item.advisorID ? '' : 'empty-field'">{{item.advisorID ? item.advisorID : '*'}}</td>
                        <td [ngClass]="item.createdAt ? '' : 'empty-field'">{{item.createdAt ? formatDate(item.createdAt) : '*'}}</td>
                        <td>
                            <button (click)="showModalRemoveClient(myModal, item.name, item.email, item.phone)" class="border-0 bg-transparent">
                                <em class="fas fa-trash"></em>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right">
            <pagination-controls (pageChange)="currentPage = $event; list()" previousLabel="Anterior" nextLabel="Próximo">
            </pagination-controls>
        </div>
    </div>
</div>

<ng-template #myModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Você tem certeza que deseja excluir {{ clientToRemove.name }}?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <h5>Dados do cliente</h5>
        <ul>
            <li>Nome completo: {{ clientToRemove.name }}</li>
            <li>Email: {{ clientToRemove.email }}</li>
            <li>Telefone: {{ clientToRemove.phone }}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" aria-label="Cancel" (click)="modal.dismiss('Cancel click')">
            <span aria-hidden="true">Cancelar</span>
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="modal.close('Save click')">Excluir</button>
    </div>
</ng-template>