import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientGetResponseDto } from 'src/app/dtos/client-get-response.dto';
import { ClientService } from 'src/app/services/client.service';
import { ProgressDto } from 'src/app/dtos/progress.dto';
import { ChartComponent } from 'ng-apexcharts';
import {
  ChartFixedCostOptions,
  ChartIfOptions,
  ChartObjectivesOptions,
  ChartPatrimonyOptions,
} from '../../charts/charts';
import { ChartIncomeDataOptions } from '../../charts/charts';
import { ChartInsuranceOptions } from '../../charts/charts';
import { FormatDate } from 'src/app/utils/formatDate.util';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CashFlowService } from 'src/app/services/cash-flow.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFilter } from 'src/app/interfaces/filter.interface';

@Component({
  selector: 'app-progress-customers',
  templateUrl: './progress-customers.component.html',
  styleUrls: ['./progress-customers.component.scss'],
})
export class ProgressCustomersComponent implements OnInit {
  resultList: any[];
  usersEmail: string[] = [];

  uniqueObjectives: string[] = [];

  FormatDate = FormatDate;

  filter: IFilter = {
    text: "",
    dateRange: {
      initialDate: null,
      finalDate: null
    },
  };

  dateRangeTest: FormGroup;

  currentPage: number = 1;
  itemsPerPage: number = 20;

  displayNone: boolean = false;

  percentage = {
    financialIndependence: 0,
    fixedCost: 0,
    incomeData: 0,
    insurance: 0,
    objectives: 0,
    patrimony: 0,
  };

  person: { name: string; age: number } = null;

  count: number = 0;

  countUnique: number = 0;

  arrayUsersPDF: { name: string; url: string }[] = [];

  arrayData = [];

  public arrayBoxChecked = [];

  arrayUserIncomplete = [];

  countNotSuccess: number = 0;

  currentPageIsChecked: boolean = false;

  @ViewChild('chart')
  pieChartIf: ChartComponent;
  public chartIfOptions: Partial<ChartIfOptions>;

  @ViewChild('chart')
  pieChartIncomeData: ChartComponent;
  public chartIncomeDataOptions: Partial<ChartIncomeDataOptions>;

  @ViewChild('chart')
  pieChartInsurance: ChartComponent;
  public chartInsuranceOptions: Partial<ChartInsuranceOptions>;

  @ViewChild('chart')
  pieChartPatrimony: ChartComponent;
  public chartPatrimonyOptions: Partial<ChartPatrimonyOptions>;

  @ViewChild('chart')
  pieChartFixedCostData: ChartComponent;
  public chartFixedCostOptions: Partial<ChartFixedCostOptions>;

  @ViewChild('chart')
  pieChartObjective: ChartComponent;
  public chartObjectivesOptions: Partial<ChartObjectivesOptions>;

  constructor(
    private ngxSpinnerService: NgxSpinnerService,
    private clientService: ClientService,
    private toastrService: ToastrService,
    private router: Router,
    private cashFlowService: CashFlowService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.ngxSpinnerService.show();
    this.dateRangeTest = this.formBuilder.group({
      initialDate: null,
      finalDate: null
    });
    this.list();
    this.listUserPdf();
  }

  listUserPdf() {
    this.clientService.listClientsPdf().subscribe(
      (success) => {
        this.arrayUsersPDF = success.pdfs;
      },
      (error) => console.log(error)
    );
  }

  list() {

    const startDate = this.dateRangeTest.value.initialDate;
    const endDate = this.dateRangeTest.value.finalDate;
    this.filter.dateRange.initialDate = startDate
    this.filter.dateRange.finalDate = endDate

    if (!this.filter.text && !this.filter.dateRange?.initialDate && !this.filter.dateRange?.finalDate) {
      this.ngxSpinnerService.show();
      this.clientService
        .listUsersByPagination(this.itemsPerPage, this.currentPage)
        .subscribe(
          (success) => {
            let successLength = success.length;
            success.forEach((item) => {
              if (item == null) return;

              this.clientService.clientProgress(item.email).subscribe(
                (success) => {
                  item.progress = success;

                  item.progress.objectivesCategory =
                    success.objectives.length > 0
                      ? this.setUniqueObjectiveCategory(
                          success.objectivesCategory
                        )
                      : null;

                  this.calcPercentage(success);

                  if (this.count == successLength - 1) {
                    this.setCharts();
                    return;
                  }

                  this.count++;
                },
                (error) => {
                  console.log('Erro', error);
                }
              );
            });
            this.resultList = success;
            this.ngxSpinnerService.hide();
          },
          (error) => console.error(error)
        );
    } else {
      this.ngxSpinnerService.show();
      this.clientService.listUserByFilter(this.filter).subscribe(
        (success) => {
          console.log('success', success);
          let successLength = success.length;
          success.forEach((item) => {
            if (item == null) return;

            this.clientService.clientProgress(item.email).subscribe(
              (success) => {
                item.progress = success;

                item.progress.objectivesCategory =
                  success.objectives.length > 0
                    ? this.setUniqueObjectiveCategory(
                        success.objectivesCategory
                      )
                    : null;

                this.calcPercentage(success);

                if (this.count == successLength - 1) {
                  this.setCharts();
                  return;
                }

                this.count++;
              },
              (error) => {
                console.log('Erro', error);
              }
            );
          });
          this.resultList = success;
          this.ngxSpinnerService.hide();
        },
        (error) => console.error(error)
      );
    }
  }

  clear() {
    this.filter.text = '';
    this.dateRangeTest.reset();
    this.list();
  }

  exportAll() {
    this.ngxSpinnerService.show();

    const startDate = this.dateRangeTest.value.initialDate;
    const endDate = this.dateRangeTest.value.finalDate;
    this.filter.dateRange.initialDate = startDate
    this.filter.dateRange.finalDate = endDate

    if (!this.filter.text && !this.filter.dateRange?.initialDate && !this.filter.dateRange?.finalDate) {

      this.clientService.listAll().subscribe(
        (success) => {
          // Obtém a quantidade de usuários.
          let usersQuantity = success.length;
          console.log(usersQuantity);

          // Define a lista de resultados com a lista de usuários retornada.
          this.resultList = success;
          console.log('Lista:', this.resultList);

          // Função para formatar datas em dd-mm-yyyy
          const formatDate = (dateString) => {
            if (!dateString) {
              return '';
            }
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
              return '';
            }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          };

          // Mapeia os dados para incluir apenas os campos específicos.
          const filteredData = this.resultList.map((user) => ({
            Nome: user?.name,
            'Data de Nascimento': formatDate(user?.birthDate),
            Profissão: user?.occupation,
            Telefone: user?.phone,
            Email: user?.email,
            ID: user?.advisorID,
            'Renda Atual': user?.currentIncome,
            Investimentos: user?.investments?.appliedValue,
            'Descobriu Número?': user?.discoverNumber ? 'SIM' : 'NÃO',
            'Open Finance': user?.klaviConnected ? 'SIM' : 'NÃO',
            Premium: user?.premium ? 'SIM' : 'NÃO',
            'Data de Criação': formatDate(user?.createdAt),
          }));

          const workbook = XLSX.utils.book_new();

          workbook.Props = {};
          workbook.Props.Title = 'Dados dos clientes ativos';

          workbook.SheetNames.push('Página 1');

          let wscols = [
            { width: 30 }, //col 1
            { width: 30 }, //col 2
            { width: 30 }, //col 3
            { width: 30 }, //col 4
            { width: 30 }, //col 5
            { width: 30 }, //col 6
            { width: 30 }, //col 7
            { width: 30 }, //col 8
            { width: 30 }, //col 9
            { width: 30 }, //col 10
            { width: 30 }, //col 11
            { width: 30 }, //col 12
          ];

          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            filteredData,
            { dateNF: 'dd-mm-yyyy' }
          );

          worksheet['!cols'] = wscols;

          workbook.Sheets['Página 1'] = worksheet;

          XLSX.writeFile(workbook, 'dados-progress-customers.xlsx');

          // Esconde o spinner de carregamento.
          this.ngxSpinnerService.hide();
        },
        (error) => {
          // Loga um erro caso a chamada para listar os usuários falhe.
          console.error(error);
        }
      );

    } else {

      this.clientService.listUserByFilter(this.filter).subscribe(
        (success) => {
          // Obtém a quantidade de usuários.
          let usersQuantity = success.length;
          console.log(usersQuantity);

          // Define a lista de resultados com a lista de usuários retornada.
          this.resultList = success;
          console.log('Lista:', this.resultList);

          // Função para formatar datas em dd-mm-yyyy
          const formatDate = (dateString) => {
            if (!dateString) {
              return '';
            }
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
              return '';
            }
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          };

          // Mapeia os dados para incluir apenas os campos específicos.
          const filteredData = this.resultList.map((user) => ({
            Nome: user?.name,
            'Data de Nascimento': formatDate(user?.birthDate),
            Profissão: user?.occupation,
            Telefone: user?.phone,
            Email: user?.email,
            ID: user?.advisorID,
            'Renda Atual': user?.currentIncome,
            Investimentos: user?.investments?.appliedValue,
            'Descobriu Número?': user?.discoverNumber ? 'SIM' : 'NÃO',
            'Open Finance': user?.klaviConnected ? 'SIM' : 'NÃO',
            Premium: user?.premium ? 'SIM' : 'NÃO',
            'Data de Criação': formatDate(user?.createdAt),
          }));

          const workbook = XLSX.utils.book_new();

          workbook.Props = {};
          workbook.Props.Title = 'Dados dos clientes ativos';

          workbook.SheetNames.push('Página 1');

          let wscols = [
            { width: 30 }, //col 1
            { width: 30 }, //col 2
            { width: 30 }, //col 3
            { width: 30 }, //col 4
            { width: 30 }, //col 5
            { width: 30 }, //col 6
            { width: 30 }, //col 7
            { width: 30 }, //col 8
            { width: 30 }, //col 9
            { width: 30 }, //col 10
            { width: 30 }, //col 11
            { width: 30 }, //col 12
          ];

          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            filteredData,
            { dateNF: 'dd-mm-yyyy' }
          );

          worksheet['!cols'] = wscols;

          workbook.Sheets['Página 1'] = worksheet;

          XLSX.writeFile(workbook, 'dados-progress-customers.xlsx');

          // Esconde o spinner de carregamento.
          this.ngxSpinnerService.hide();
        },
        (error) => {
          // Loga um erro caso a chamada para listar os usuários falhe.
          console.error(error);
        }
      );
    }
  }

  showGraphic() {
    this.displayNone = this.displayNone ? false : true;
  }

  calcPercentage(success: ProgressDto) {
    success?.financialIndependence
      ? this.percentage.financialIndependence++
      : null;
    success?.fixedCost ? this.percentage.fixedCost++ : null;
    success?.incomeData ? this.percentage.incomeData++ : null;
    success?.insurance ? this.percentage.insurance++ : null;
    success.objectives?.length > 0 ? this.percentage.objectives++ : null;
    success?.patrimony ? this.percentage.patrimony++ : null;
  }

  setUniqueObjectiveCategory(objectivesCategory) {
    objectivesCategory.forEach((category) => {
      if (
        this.uniqueObjectives &&
        !this.uniqueObjectives.includes(category.name)
      ) {
        this.uniqueObjectives.push(category.name);
      }

      if (this.countUnique == objectivesCategory.length - 1) {
        objectivesCategory = this.uniqueObjectives;
        this.uniqueObjectives = [];
        this.countUnique = 0;
      }

      this.countUnique++;
    });

    return objectivesCategory;
  }

  setCharts() {
    this.chartIfOptions = {
      series: [
        this.percentage.financialIndependence,
        this.resultList.length - this.percentage.financialIndependence,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.financialIndependence}`,
        `Não preenchido ${
          this.resultList.length - this.percentage.financialIndependence
        }`,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.chartIncomeDataOptions = {
      series: [
        this.percentage.incomeData,
        this.resultList.length - this.percentage.incomeData,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.incomeData}`,
        `Não preenchido ${this.resultList.length - this.percentage.incomeData}`,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.chartInsuranceOptions = {
      series: [
        this.percentage.insurance,
        this.resultList.length - this.percentage.insurance,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.insurance}`,
        `Não preenchido ${this.resultList.length - this.percentage.insurance}`,
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.chartPatrimonyOptions = {
      series: [
        this.percentage.patrimony,
        this.resultList.length - this.percentage.patrimony,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.patrimony}`,
        `Não preenchido ${this.resultList.length - this.percentage.patrimony}`,
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.chartFixedCostOptions = {
      series: [
        this.percentage.fixedCost,
        this.resultList.length - this.percentage.fixedCost,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.fixedCost}`,
        `Não preenchido ${this.resultList.length - this.percentage.fixedCost}`,
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    this.chartObjectivesOptions = {
      series: [
        this.percentage.objectives,
        this.resultList.length - this.percentage.objectives,
      ],
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      labels: [
        `Preenchido ${this.percentage.objectives}`,
        `Não preenchido ${this.resultList.length - this.percentage.objectives}`,
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  onTableSizeChange(size: number) {
    this.itemsPerPage = size;
    this.list();
  }

  userHavePdf(email: string) {
    if (this.arrayUsersPDF.filter((el) => el.name.includes(email)).length > 0) {
      return true;
    }
    return false;
  }

  async exportUserPDF(userEmail: string) {
    const fileUrl =
      'https://walkr-app-pdfs.s3.amazonaws.com/MeuProjetoDeVida_' +
      userEmail +
      '.pdf';
    window.open(fileUrl, '_blank');
  }

  exportNamePhone() {
    const workbook = XLSX.utils.book_new();

    workbook.Props = {};
    workbook.Props.Title = 'Dados dos clientes ativos';

    workbook.SheetNames.push('Página 1');

    let wscols = [
      { width: 30 }, //col 1
      { width: 30 }, //col 2
      { width: 30 }, //col 3
      { width: 30 }, //col 4
      { width: 30 }, //col 5
      { width: 30 }, //col 6
      { width: 30 }, //col 7
      { width: 30 }, //col 8
      { width: 30 }, //col 9
      { width: 30 }, //col 10
      { width: 30 }, //col 11
      { width: 30 }, //col 12
    ];

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.arrayData);

    worksheet['!cols'] = wscols;

    workbook.Sheets['Página 1'] = worksheet;

    workbook.Sheets['Página 1']['A1'].v = 'Nome';
    workbook.Sheets['Página 1']['B1'].v = 'Data de Nascimento';
    workbook.Sheets['Página 1']['C1'].v = 'Profissão';
    workbook.Sheets['Página 1']['D1'].v = 'Telefone';
    workbook.Sheets['Página 1']['E1'].v = 'Email';
    workbook.Sheets['Página 1']['F1'].v = 'ID';
    workbook.Sheets['Página 1']['G1'].v = 'Renda Atual';
    workbook.Sheets['Página 1']['H1'].v = 'Investimentos';
    workbook.Sheets['Página 1']['I1'].v = 'Descobriu Número?';
    workbook.Sheets['Página 1']['J1'].v = 'Open Finance';
    workbook.Sheets['Página 1']['K1'].v = 'Premium';
    workbook.Sheets['Página 1']['L1'].v = 'Data de Criação';

    XLSX.writeFile(workbook, 'dados-progress-customers.xlsx');
    this.arrayData = [];
    const length = Number(this.itemsPerPage);
    new Array(length).fill(0).forEach((_, index) => {
      const checkboxElement: HTMLInputElement = document.getElementById(
        `Checkbox${index}`
      ) as HTMLInputElement;
      if (checkboxElement.checked)
        checkboxElement.checked = !checkboxElement.checked;
    });
  }

  routeViewClients() {
    this.verifyIncompleteClients();

    setTimeout(() => {
      if (this.arrayBoxChecked.length > 0) {
        this.router.navigate(
          ['/pages/view-customers-active/view-customers-exported'],
          {
            state: {
              users: this.arrayBoxChecked,
            },
          }
        );
      } else {
        this.toastrService.warning(
          'Por favor, insira pelo menos um usuário válido para visualização',
          'Usuário inexistente',
          {
            progressBar: true,
          }
        );
      }
    }, 500);
  }

  verifyIncompleteClients() {
    this.arrayBoxChecked.forEach((userEmail) => {
      this.cashFlowService.get(userEmail).subscribe(
        (success) => {
          if (!success || !success.report || success.report.length <= 0) {
            this.insertUserIncomplete(userEmail);
          }
        },
        (error) => {
          this.insertUserIncomplete(userEmail);
        }
      );
    });
  }

  insertUserIncomplete(userEmail) {
    this.arrayUserIncomplete.push(userEmail);

    let index = this.arrayBoxChecked.indexOf(userEmail);
    this.arrayBoxChecked.splice(index, 1);

    setTimeout(() => {
      if (this.arrayUserIncomplete.length - 1 == this.countNotSuccess) {
        this.showMessageIncompletUser();
      } else {
        this.countNotSuccess++;
      }
    }, 300);
  }

  showMessageIncompletUser() {
    this.toastrService.error(
      `${this.arrayUserIncomplete.length} usuários não completaram o projeto de vida.\nPor esse motivo não serão exportados\n`,
      'Fluxo inexistente!',
      {
        progressBar: true,
      }
    );
    this.arrayUserIncomplete = [];
  }

  selectedClient(
    event,
    userName: string,
    userBirthDate: string,
    userOccupation: string,
    userPhone: string,
    userEmail: string,
    advisorID: string,
    currentIncome,
    investments,
    discoverNumber,
    klaviConnected,
    premium,
    projectDate
  ) {
    discoverNumber = discoverNumber ? 'SIM' : 'NÃO';
    currentIncome =
      currentIncome % 1 !== 0 ? currentIncome.toFixed(2) : currentIncome || 0;
    investments = investments || 0;
    klaviConnected = klaviConnected ? 'SIM' : 'NÃO';
    premium = premium ? 'SIM' : 'NÃO';
    if (event.target.checked == true) {
      this.arrayBoxChecked.push(userEmail);
      this.arrayData.push({
        name: userName,
        birthDate: userBirthDate,
        occupation: userOccupation,
        phone: userPhone,
        email: userEmail,
        advisorID: advisorID,
        currentIncome,
        investments,
        discoverNumber,
        klaviConnected,
        premium,
        projectDate,
      });
    } else {
      if (this.arrayBoxChecked.length != 0) {
        let indexBox = this.arrayBoxChecked.indexOf(userEmail);
        let indexName = this.arrayBoxChecked.indexOf({
          name: userName,
          birthDate: userBirthDate,
          occupation: userOccupation,
          phone: userPhone,
          email: userEmail,
          advisorID: advisorID,
          currentIncome,
          investments,
          discoverNumber,
          klaviConnected,
          premium,
          projectDate,
        });
        this.arrayBoxChecked.splice(indexBox, 1);
        this.arrayData.splice(indexName, 1);
      }
    }
  }

  selectCurrentPage() {
    const end = this.itemsPerPage * this.currentPage;
    const start = end - this.itemsPerPage;

    // push customers to arrayboxchecked
    const customersPage = this.resultList.slice(start, end);

    !this.currentPageIsChecked &&
      customersPage.map((item) => {
        const {
          name,
          birthDate,
          occupation,
          phone,
          email,
          advisorID,
          currentIncome,
          discoverNumber,
          klaviConnected,
          premium,
          createdAt,
        } = item;
        const currentIncomeFormatted =
          currentIncome % 1 !== 0
            ? currentIncome.toFixed(2)
            : currentIncome || 0;
        const investments = item?.investments?.appliedValue || 0;
        const discoverNumberFormatted = discoverNumber ? 'SIM' : 'NÃO';
        const klaviFormatted = klaviConnected ? 'SIM' : 'NÃO';
        const premiumFormatted = premium ? 'SIM' : 'NÃO';
        const birthDateFormatted = birthDate ? FormatDate(birthDate) : '';
        const createdAtFormatted = createdAt ? FormatDate(createdAt) : '';
        this.arrayData.push({
          name,
          birthDateFormatted,
          occupation,
          phone,
          email,
          advisorID,
          currentIncomeFormatted,
          investments,
          discoverNumberFormatted,
          klaviFormatted,
          premiumFormatted,
          createdAtFormatted,
        });
        this.arrayBoxChecked.push(email);
      });

    // check Checkbox
    const length = Number(this.itemsPerPage);

    new Array(length).fill(0).forEach((_, index) => {
      const checkboxElement: HTMLInputElement = document.getElementById(
        `Checkbox${index}`
      ) as HTMLInputElement;

      checkboxElement.checked = !checkboxElement.checked;
    });

    this.currentPageIsChecked = !this.currentPageIsChecked;
  }

  onPageChange(event) {
    this.currentPage = event;
    this.currentPageIsChecked = false;
    this.list();
  }

  onChangeTerm(text: any) {
    if (!text) this.filter.text = '';
    else {
      this.filter = text;
      this.list();
    }
  }
}
